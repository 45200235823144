// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessing-rolvedon-index-mdx": () => import("./../../../src/pages/accessing-rolvedon/index.mdx" /* webpackChunkName: "component---src-pages-accessing-rolvedon-index-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-patient-support-index-mdx": () => import("./../../../src/pages/patient-support/index.mdx" /* webpackChunkName: "component---src-pages-patient-support-index-mdx" */),
  "component---src-pages-reimbursement-mdx": () => import("./../../../src/pages/reimbursement.mdx" /* webpackChunkName: "component---src-pages-reimbursement-mdx" */),
  "component---src-pages-resources-mdx": () => import("./../../../src/pages/resources.mdx" /* webpackChunkName: "component---src-pages-resources-mdx" */),
  "component---src-pages-sitemap-mdx": () => import("./../../../src/pages/sitemap.mdx" /* webpackChunkName: "component---src-pages-sitemap-mdx" */)
}

