import React from "react";
import computerSmall from "./computer-small.svg";
import computer from "./computer.svg";
import "./styles.scss";

export default function PortalServices() {
  return (
    <div className="access-portal-services-wrapper">
      <div className="access-portal-services">
        <div className="container access-portal-services-inner-container">
          <img
            src={computer}
            className="access-portal-computer"
            alt="Computer graphic"
          />
          <img
            src={computerSmall}
            className="access-portal-computer access-portal-computer-small"
            alt="Computer graphic"
          />

          <h2>Portal Services</h2>

          <ul>
            <li>Real-time information on approval status</li>
            <li>Electronic upload of patient documentation</li>
            <li>Electronic signatures</li>
            <li>Simple and easy navigation</li>
            <li>Track patient status in real time</li>
            <li>Secure messaging with your Reimbursement Specialists</li>
          </ul>

          <h3 style={{ fontSize: "16px" }}>Questions?</h3>
          <p>
            Connect with your Field Reimbursement Manager for more information
            about ACCESS4Me and a demo of the Provider Portal.
          </p>
        </div>
      </div>
    </div>
  );
}
