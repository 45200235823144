import { Link } from "@reusable";
import React from "react";
import "./styles.scss";

/*
  Looks like a styled button, but can be any element
  with the "as" prop.
*/
export default function Button({
  children,
  as,
  className = "",
  to,
  openInNewTab = false,
  kind,
  ...rest
}) {
  const Element = as ? as : "button";
  const classNameKind = kind ? kind : "";

  if (openInNewTab) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
  }

  if (to) {
    return (
      <Link
        to={to}
        data-btn
        className={`btn ${className} ${classNameKind}`}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <Element data-btn className={`btn ${className} ${classNameKind}`} {...rest}>
      {children}
    </Element>
  );
}
