import { Button, IconDownload } from "@reusable";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Helmet } from "react-helmet";
import "./styles.scss";

export default function Resources() {
  const data = useStaticQuery(graphql`
    query {
      allDataJson {
        nodes {
          resources {
            id
            name
            anchor
            description
            file {
              name
              label
            }
            image
          }
          resource_groups {
            name
            resources {
              id
            }
            groups {
              resources {
                id
              }
              name
            }
          }
        }
      }
      files: allFile(filter: { sourceInstanceName: { eq: "resource-files" } }) {
        nodes {
          name
          ext
          publicURL
        }
      }
      images: allFile(
        filter: { sourceInstanceName: { eq: "resource-images" } }
      ) {
        nodes {
          name
          ext
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `);

  const { resources, resource_groups } = data.allDataJson.nodes[0];
  const files = data.files.nodes;
  const images = data.images.nodes;

  return (
    <>
      {" "}
      <Helmet>
        <title>Resources | ACCESS4Me and ROLVEDON® (eflapegrastim-xnst)</title>
        <link rel="canonical" href="https://www.access4me.com/resources" />
        <meta property="og:url" content="https://www.access4me.com/resources" />
        <meta
          name="twitter:url"
          content="https://www.access4me.com/resources"
        />
        <meta
          name="description"
          content="ACCESS4Me resources for ROLVEDON are available to download online, including user guides, forms, documents, reimbursement tools and program brochures.
          "
        />
        <meta
          name="keywords"
          content="ACCESS4Me resources, eflapegrastim resources, rolvedon resources, ACCESS4Me downloads, eflapegrastim downloads, rolvedon downloads,  ACCESS4Me forms, eflapegrastim forms, rolvedon forms, ACCESS4Me tools, eflapegrastim tools, rolvedon tools, ACCESS4Me brochures, eflapegrastim brochures, rolvedon brochures, ACCESS4Me guide, eflapegrastim guide, rolvedon guide, ACCESS4Me guide, eflapegrastim guide, rolvedon guide,
       "
        />
      </Helmet>
      <div className="container animate-on-enter-1">
        {resource_groups.map((group) => (
          <React.Fragment key={group.name}>
            <h2
              className="resource-group-heading"
              id={group.id}
              dangerouslySetInnerHTML={{
                __html: group.name,
              }}
            />
            {group.resources && (
              <ResourceCardList
                group={group}
                resources={resources}
                headingLevel="3"
                files={files}
                images={images}
              />
            )}
            {group.groups && (
              <React.Fragment>
                {group.groups.map((subgroup, i) => (
                  <React.Fragment key={subgroup.name + i}>
                    {subgroup.name && (
                      <h3
                        className="resource-group-subheading"
                        dangerouslySetInnerHTML={{
                          __html: subgroup.name,
                        }}
                      />
                    )}
                    <ResourceCardList
                      group={subgroup}
                      resources={resources}
                      headingLevel="4"
                      files={files}
                      images={images}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

function ResourceCardList({ group, ...rest }) {
  return (
    <ul className="resource-list">
      {group.resources.map((resource) => (
        <li key={resource.id}>
          <ResourceCard id={resource.id} {...rest} />
        </li>
      ))}
    </ul>
  );
}

function ResourceCard({ id, resources, files, images, headingLevel }) {
  /*
    Lookup the resource, file, and image by resource id.

    These look ups could also happen at build during a transformation
    process, but this is OK. If this page was much larger, this
    should be moved to a build process. 
  */
  const resource = resources.find((resource) => resource.id === id);
  const file = files.find(
    (file) => file.name + file.ext === resource.file.name,
  );
  const image = images.find(
    (image) => image.name + image.ext === resource.image,
  );
  const alt =
    image && image.name ? image.name.replace(/_|-/g, " ") : "FallbackAltText";
  const Heading = "h" + headingLevel;

  return (
    <div id={resource.anchor || undefined} className="resource">
      <ResourceImage
        image={image && image.childImageSharp && image.childImageSharp.fluid}
        alt={alt}
      />

      <div className="resource-card">
        <Heading
          className="resource-heading"
          dangerouslySetInnerHTML={{
            __html: resource.name,
          }}
        />

        <p
          className="resource-description"
          dangerouslySetInnerHTML={{
            __html: resource.description,
          }}
        />

        <Button
          as="a"
          href={file?.publicURL}
          className="resource-link main"
          openInNewTab={true}
        >
          <span style={{ marginRight: "0.75rem" }}>{resource.file.label}</span>{" "}
          <IconDownload className="resource-link-icon" />
        </Button>
        <Button
          as="a"
          href={file?.publicURL}
          className="resource-link alternate"
          download
        >
          <span style={{ marginRight: "0.75rem" }}>{resource.file.label}</span>{" "}
          <IconDownload className="resource-link-icon" />
        </Button>
      </div>
    </div>
  );
}

function ResourceImage({ image, alt }) {
  /*
  return (
    <BackgroundImage
      aria-hidden="true"
      tag="div"
      fluid={image}
      className="resource-image"
    />
  );
  */

  return <Img fluid={image} className="resource-image" alt={alt} />;
}
