import { AnchorLink } from "gatsby-plugin-anchor-links";
import PatientSupportAccordion from "@components/patient-support-accordion";
import PatientSupportImage from "@components/patient-support-image";
import { Accordion, AccordionItem, AccordionHeading, AccordionPanel, Button, IconArrowRight, Link } from "@reusable";
import "../../../../src/pages/patient-support/styles.scss";
import coPayCardGraphic from "../../../../src/pages/patient-support/co-pay-card.svg";
import * as React from 'react';
export default {
  AnchorLink,
  PatientSupportAccordion,
  PatientSupportImage,
  Accordion,
  AccordionItem,
  AccordionHeading,
  AccordionPanel,
  Button,
  IconArrowRight,
  Link,
  coPayCardGraphic,
  React
};