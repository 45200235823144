import { COLORS } from "@reusable";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import "./styles.scss";

export default function PatientSupportImage() {
  const data = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "patient-support" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Circles />
      <BackgroundImage
        aria-hidden="true"
        tag="div"
        fluid={data.image.childImageSharp.fluid}
        className="patient-support-image"
        style={{
          backgrounPosition: "right",
        }}
        alt="Patient Support imagery"
      />
    </React.Fragment>
  );
}

function Circles() {
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: "2rem",
          left: "-2rem",
          background: COLORS.YELLOW,
          height: "48px",
          width: "48px",
          borderRadius: "50%",
          zIndex: "2",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "6rem",
          left: "-2rem",
          background: COLORS.BLUE,
          height: "24px",
          width: "24px",
          borderRadius: "50%",
          zIndex: "2",
        }}
      />
    </React.Fragment>
  );
}
