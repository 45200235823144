import {
  Accordion as ReachAccordion,
  AccordionButton as ReachAccordionButton,
  AccordionItem as ReachAccordionItem,
  AccordionPanel as ReachAccordionPanel,
} from "@reach/accordion";
import "@reach/accordion/styles.css";
import { COLORS, IconCollapse, IconOpen } from "@reusable";
import React from "react";
import "./styles.scss";

/**
 * A wrapper for @reach/accordion with project styles
 * and some more opinions, like a built in heading for
 * AccordionButton.
 * 
 * - See full docs for usage options: https://reach.tech/accordion/
 * - ARIA pattern: https://www.w3.org/TR/wai-aria-practices-1.2/#accordion
 * 
 * Example:
 * 
 * ```js
 *  import {
 *    Accordion,
 *    AccordionItem,
 *    AccordionHeading,
 *    AccordionPanel
 * } from @reusable
 * 
 * function AccordionExample() {
    return (
      <Accordion>
        <AccordionItem>
          <AccordionHeading>Benefits Investigation</AccordionHeading>
          <AccordionPanel>
            Here are some detailed instructions about doing a thing. I am very
            complex and probably contain a lot of content, so a user can hide or
            show me by clicking the button above.
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeading>Prior Authorization Assistance</AccordionHeading>
          <AccordionPanel>
            Here are some detailed instructions about doing yet another thing.
            There are a lot of things someone might want to do, so I am only going
            to talk about doing that other thing. I'll let my fellow accordion
            items go into detail about even more things.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }
 * ```
 */

export function Accordion(props) {
  return (
    <ReachAccordion
      style={{
        maxWidth: "900px",
        margin: "0 auto",
        marginBottom: "2rem",
      }}
      {...props}
    />
  );
}

export function AccordionItem(props) {
  return <ReachAccordionItem {...props} />;
}

/**
 * <AccordionHeading />
 *
 * To improve the semantics of the markup further, the ARIA
 * guidelines dictate that each accordion item's button should
 * be wrapped in an element with role="heading", or more simply,
 * and HTML heading tag.
 *
 * Defaults to heading level 2.
 */
export function AccordionHeading({ headingLevel = 2, children, ...rest }) {
  const Heading = "h" + headingLevel;

  return (
    <Heading
      style={{
        marginBottom: "0",
        marginTop: "0.75rem",
      }}
    >
      <ReachAccordionButton
        style={{
          width: "100%",
          color: COLORS.PURPLE,
          background: COLORS.GRAY.LIGHT,
          fontSize: "18px",
          textAlign: "left",
          fontWeight: "600",
          padding: "1rem 1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "none",
          margin: "0",
        }}
        {...rest}
      >
        <div>{children}</div>
        <IconOpen data-accordion-icon-open />
        <IconCollapse data-accordion-icon-collapse />
      </ReachAccordionButton>
    </Heading>
  );
}

export function AccordionPanel(props) {
  return (
    <ReachAccordionPanel
      style={{
        border: `1px solid ${COLORS.GRAY.MEDIUM}`,
        padding: "24px",
        background: "white",
      }}
      {...props}
    />
  );
}
