import { Link } from "@reusable";

import { graphql, useStaticQuery } from "gatsby";

import React from "react";

import "./styles.scss";

 

export default function NavigationList({

  hcp = false,

  home = false,

  variation,

}) {

  // Page data for use in menus

  const data = useStaticQuery(graphql`

    query SiteNavigationQuery {

      navItems: allMdx(

        filter: { frontmatter: { path: { regex: "/" }, order: { gt: 0 } } }

        sort: { fields: frontmatter___order }

      ) {

        nodes {

          frontmatter {

            path

            title

            briefTitle

          }

        }

      }

    }

  `);

  const items = data.navItems.nodes;

  const classVariation =

    variation === "sitemap" ? "site-nav-list--sitemap" : "";

 

  return (

    <ul className={"site-nav-list " + classVariation}>

      {home && (

        <li className="site-nav-list-item site-nav-list-item--alt">

          <Link to="/">Home</Link>

        </li>

      )}

      {items.map((item) => {

        const { path, briefTitle } = item.frontmatter;

 

        return (

          <li className="site-nav-list-item" key={path + briefTitle}>

            <Link

              key={path}

              to={path}

              dangerouslySetInnerHTML={{

                __html: briefTitle,

              }}

            />
           {briefTitle === "Resources" && variation !== "sitemap" && (
        <ul className="dropdown top-drop">
          <li><Link to="/resources/#hcp">HCP</Link></li>
          <li><Link to="/resources/#sample-letters">Sample Letters</Link></li>
          <li><Link to="/resources/#patients">Patients</Link></li>
        </ul>
      )}

          </li>

        );

      })}

      {hcp && (

        <li className="site-nav-list-item site-nav-list-item--alt">

          <Link to="https://rolvedon.com/">Visit HCP site</Link>

        </li>

      )}

    </ul>

  );

}

 