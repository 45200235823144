import PatientSupportImage from "@components/patient-support-image";
import {
  Accordion,
  AccordionHeading,
  AccordionItem,
  AccordionPanel,
  Button,
  IconArrowRight,
  Link,
  Modal,
} from "@reusable";
import React, { useState } from "react";
import { Helmet } from "react-helmet";

export default function PatientSupportAccordion() {
  return (
    <>
      <Helmet>
        <title>Patient Support | ROLVEDON® (eflapegrastim-xnst)</title>
        <link
          rel="canonical"
          href="https://www.access4me.com/patient-support"
        />
        <meta
          property="og:url"
          content="https://www.access4me.com/patient-support"
        />
        <meta
          name="twitter:url"
          content="https://www.access4me.com/patient-support"
        />
        <meta
          name="description"
          content="ACCESS4Me programs for ROLVEDON include first cycle patient support, copay assistance, patient assistance and independent charitable foundation information."
        />
        <meta
          name="keywords"
          content="ACCESS4Me support, ACCESS4Me assistance, eflapegrastim patient assistance, rolvedon patient assistance, eflapegrastim first cycle, rolvedon first cycle, rolvedon copay, eflapegrastim copay, ACCESS4Me programs, rolvedon programs, eflapegrastim programs, rolvedon support, eflapegrastim support, Rolvedon PAP,  eflapegrastim PAP, Rolvedon foundation,  eflapegrastim foundation, independant charity foundation"
        />
      </Helmet>
      <div className="enroll-acc">
        <Accordion collapsible>
          <AccordionItem>
            <AccordionHeading>
              Bridge Program (Office&nbsp;Settings&nbsp;Only)
            </AccordionHeading>
            <AccordionPanel>
              <p>
                <strong>
                  Commercially insured patients can receive one free dose of
                  ROLVEDON free of charge for patients that are:
                  <ul>
                    <li> Experiencing a lapse or delay in coverage </li>
                    <li>
                      {" "}
                      Waiting for approval from their insurance provider{" "}
                    </li>
                  </ul>
                </strong>
              </p>

              <section
                className="bg-gray"
                style={{
                  marginLeft: "-1.5rem",
                  marginRight: "-1.5rem",
                  padding: "1.5rem",
                }}
              >
                <p>Patient Eligibility</p>
                <ul>
                  <li>Patient must be new to ROLVEDON</li>
                  <li>Limited to ONE free dose* per patient</li>
                  <li>Must have a commercial/private coverage per patient</li>
                  <li>Prescribed FDA-approved indication</li>
                  <li>No income requirement</li>
                  <li>
                    Will experience an insurance-related access delay due to a
                    requirement for Prior Authorization approval
                  </li>
                  <li>Patient has medical or prescription drug insurance</li>
                  <li>Patient must be 18 years of age and reside in the US</li>
                  <li>ROLVEDON is administered in office settings only</li>
                  <p
                    class="hang-footnote"
                    style={{
                      marginLeft: "-18px",
                    }}
                  >
                    *Eligibility subject to all terms and conditions of
                    ACCESS4Me and the Bridge program. Contact ACCESS4Me or your
                    Field Reimbursement Manager for complete{""}
                    <a
                      href="/pdf/ACCESS4Me-Bridge-Program-T&C.pdf"
                      target="_blank"
                    >
                      {" "}
                      terms and conditions
                    </a>{" "}
                    including limitations and availability.
                  </p>
                </ul>
              </section>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeading>Copay Assistance Program</AccordionHeading>
            <AccordionPanel>
              <div
                className="one-column"
                style={{
                  margin: "0rem 0",
                }}
              >
                <div>
                  <ul
                    style={{
                      marginTop: "2rem",
                    }}
                  >
                    <li>
                      Reduce out-of-pocket costs for patients with commercial
                      insurance
                    </li>
                    <li>
                      Pay as little as $0 out-of-pocket cost for eligible
                      patients with commercial insurance
                    </li>
                    <li>
                      Maximum annual benefit of $15,000 per 12-month enrollment
                      period
                    </li>
                    <li>Maximum of $5,500 per script</li>
                    <li>No income requirement</li>
                  </ul>

                  <div className="sm-center">
                    <Button to="https://www.ACCESS4MePortal.com">
                      Apply for a Copay Card{" "}
                      <IconArrowRight style={{ marginLeft: "0.5rem" }} />
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1.5rem" }}>
                <p class="text-center">
                  Upon approval, the copay card is activated and shipped
                  directly to the provider or injection center.
                </p>

                <p class="text-center footnote">
                  The program covers<sup>&dagger;</sup> up to $15,000 in
                  assistance per year toward product-specific copay,
                  coinsurance, and insurance deductibles for ROLVEDON
                  treatments. Patients are responsible for any additional copay
                  costs that exceed the program assistance limit.
                </p>
              </div>
              <div className="enroll-acc">
                <section
                  className="bg-gray"
                  style={{
                    marginLeft: "-1.5rem",
                    marginRight: "-1.5rem",
                    padding: "1.5rem",
                  }}
                >
                  <p>Patient Eligibility</p>
                  <ul>
                    <li>
                      Must have commercial or private insurance that covers
                      ROLVEDON
                    </li>
                    <li>Must have a copay for ROLVEDON</li>
                    <li>Must be a resident of the United States</li>
                    <li>
                      Diagnosis that is consistent with the FDA-approved
                      indication for ROLVEDON
                    </li>
                  </ul>
                </section>
              </div>
              <br />
              <p className="text-small hang-footnote">
                <sup>&dagger;</sup>Subject to annual assistance limit. Not an
                insurance or debit card program. This program is not valid for
                prescriptions covered by or submitted for reimbursement under
                Medicaid, Medicare, VA, DOD, TRICARE, or similar federal or
                state programs. This program does not cover or provide support
                for supplies, procedures, or any physician-related service
                associated with ROLVEDON. General, non–product-specific copay,
                coinsurance, or insurance deductibles are not covered. This
                program is not valid where prohibited by law, taxed, or
                restricted. ACCESS4Me reserves the right to rescind, revoke,
                terminate, or amend this offer, eligibility, and terms of use at
                any time without notice. Additional program conditions may
                apply.
              </p>
              <p className="text-small">
                This Copay Assistance Program is not health insurance. The Copay
                Assistance Program is not transferable, and the amount of the
                savings cannot exceed the patient's out-of-pocket costs. Cannot
                be combined with any other rebate/coupon, cash discount card,
                free trial, or similar offer for the specified prescription.
                This copay assistance is not redeemable for cash. This copay
                assistance is not valid for product dispensed by a 340B covered
                entity that purchased the product at discounted pricing under
                the 340B drug pricing program. This copay assistance is not
                valid if the patient's commercial health insurance plan or
                pharmacy benefit manager uses a copay adjustment program (often
                termed "maximizer" or "accumulator" program) that restricts any
                form of copay assistance from being counted toward the patient's
                cost-sharing limits.
              </p>

              {/* <p className="center ctas">
                { <Link
                  href="https://www.ACCESS4MePortal.com"
                  style={{
                    fontWeight: 600,
                    color: "#87189d",
                    textDecoration: "none",
                  }}
                >
                  Visit ACCESS4MePortal.com for online enrollment and access to
                  tools, forms and resources.
                </Link> }
                <span
                  style={{
                    display: "inline-block",
                    color: "white",
                    background: "#87189d",
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    marginLeft: "0.75rem",
                  }}
                >
                  {/* <IconArrowRight /> }
                </span>
              </p> */}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeading>
              ROLVEDON Patient Assistance Program (PAP)
            </AccordionHeading>
            <AccordionPanel>
              <p>
                <strong>
                  Patients who are uninsured or underinsured may be eligible to
                  receive ROLVEDON at no cost through the Patient Assistance
                  Program
                </strong>
              </p>

              <section
                className="bg-gray"
                style={{
                  marginLeft: "-1.5rem",
                  marginRight: "-1.5rem",
                  padding: "1.5rem",
                }}
              >
                <p>
                  Patient Eligibility<sup>&Dagger;</sup>
                </p>
                <ul>
                  <li>Uninsured or underinsured for ROLVEDON</li>
                  <li>Must be a resident of the United States</li>
                  <li>Demonstrates financial need</li>
                  <li>
                    Eligible patients must have an annual income of {"<"}500% of
                    the federal poverty level (FPL) adjusted for family size
                    (eg, $156,000/year for a family of 4*). See{" "}
                    <Link href="https://aspe.hhs.gov/poverty-guidelines">
                      www.aspe.hhs.gov/poverty-guidelines
                    </Link>{" "}
                    for US federal poverty guidelines
                  </li>
                </ul>
                <p className="text-small hang-footnote">*based on 2024 data</p>
                <p className="text-small hang-footnote">
                  Spectrum Pharmaceuticals, Inc. is not responsible for the
                  content displayed on this website
                </p>
                <PovertyChartModal />

                <p>
                  <strong>
                    ACCESS4Me will independently verify a patient's income with
                    our income validation tool.<sup>&Dagger;</sup>
                  </strong>
                </p>

                <p className="text-small hang-footnote">
                  <sup>&Dagger;</sup>If the income validation tool is unable to
                  return results, the patient will be required by ACCESS4Me to
                  provide proof of income. Proof of income may also be requested
                  at any time for audit/verification.
                </p>
              </section>

              <p>
                <strong>
                  Replacement product for approved patients is shipped on a
                  monthly basis directly to the facility address where the
                  product was administered.{" "}
                  <a
                    target={"_blank"}
                    href="../../pdf/Access4ME-ROLVEDON-Product-Replacement-Form.pdf"
                    rel="noopener noreferrer"
                  >
                    Product Replacement Form{" "}
                  </a>
                  required.
                </strong>
              </p>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeading>
              First-Cycle Patient Support Program (Hospital Outpatient Settings
              Only)
            </AccordionHeading>
            <AccordionPanel>
              <h2
                style={{
                  marginBottom: 0,
                  marginTop: "1rem",
                }}
              >
                FIRST-CYCLE Patient Support program <br />
                (Hospital Outpatient Settings Only)
              </h2>
              <p
                className="text-large text-center"
                style={{ marginTop: 0, marginBottom: "2rem" }}
              >
                No income requirement
              </p>

              <div className="patient-support-image-layout">
                <div className="enroll-acc">
                  Eligible new patients can receive their first-cycle dose of
                  ROLVEDON free of charge. When enrolling the patient, simply
                  check First-Cycle Patient Support on the enrollment form.
                  <sup>&sect;</sup>
                  ACCESS4Me will conduct the benefits investigation, communicate
                  the results to your office, and confirm eligibility for the
                  free dose.
                  <section
                    className="bg-gray"
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <p>Patient Eligibility</p>
                    <ul>
                      <li>
                        Limited to ONE dose<sup>&sect;</sup> per patient
                      </li>
                      <li>Patient must be new to ROLVEDON</li>
                      <li>
                        Patient must be a US resident with a legal US mailing
                        address
                      </li>
                      <li>
                        Patient’s diagnosis is consistent with the FDA-approved
                        indication for ROLVEDON
                      </li>
                      <li>
                        Patient is enrolled in ACCESS4Me Patient Support Program{" "}
                      </li>
                      <li>
                        ROLVEDON is administered in hospital outpatient settings
                        only
                      </li>
                    </ul>
                  </section>
                  <br />
                  <p class="text-small hang-footnote">
                    <sup>&sect;</sup>Enrollment form must be received prior to
                    the date of injection. Replacement product for approved
                    patients is shipped directly to the facility address where
                    the product was administered.
                  </p>
                  Utilization limits apply. Contact ACCESS4Me or your Field
                  Reimbursement Manager for complete{" "}
                  <a
                    href="/pdf/ACCESS4Me-First-Cycle-Patient-Support-Program-TC.pdf"
                    target="_blank"
                  >
                    {" "}
                    terms and conditions
                  </a>{" "}
                  or visit the Resources section.
                </div>
                <div className="patient-support-image-center-wrapper">
                  <div className="patient-support-image-container">
                    <PatientSupportImage />
                  </div>
                </div>
              </div>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeading>
              Independent Charitable Foundation Information
            </AccordionHeading>
            <AccordionPanel>
              <p>
                <strong>
                  ACCESS4Me can provide information about financial assistance
                  from independent charitable foundations.
                  <sup>&#x22A0;</sup>
                </strong>
              </p>
              <p>
                <strong>Reimbursement Specialists can help patients by:</strong>
              </p>
              <ul>
                <li>
                  Verifying coverage and determining patient out-of-pocket costs
                  for ROLVEDON
                </li>
                <li>
                  Identifying independent charitable foundations with available
                  funding for approved indications
                  <ul>
                    <li>
                      Provide information to assist patient with applying to the
                      foundation chosen by the patient
                    </li>
                  </ul>
                </li>
                <li>
                  Communicating with physician's office throughout the referral
                  process
                </li>
              </ul>
              <p className="text-small hang-footnote">
                <sup>&#x22A0;</sup>Independent foundations have their own
                eligibility rules and we cannot guarantee a foundation will help
                you. We do not endorse or prefer any particular foundation.
              </p>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
}

function PovertyChartModal() {
  const [open, setOpen] = useState(false);

  return (
    <div className="sm-center">
      <Button className="poverty-chart-gtm" onClick={() => setOpen(true)}>
        <span
          style={{
            marginRight: "0.75rem",
          }}
        >
          View Poverty Chart
        </span>{" "}
        <IconModalOpen />
      </Button>
      {open && (
        <Modal id="poverty-modal-gtm" handleDismiss={() => setOpen(false)}>
          <table>
            <caption>
              2024 federal poverty level percentages for the 48 contiguous
              states and the District of Columbia
            </caption>
            <thead>
              <tr>
                <th>Persons in family / household</th>
                <th>500% of federal poverty level</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>$75,300</td>
              </tr>
              <tr>
                <td>2</td>
                <td>$102,200</td>
              </tr>
              <tr>
                <td>3</td>
                <td>$129,100</td>
              </tr>
              <tr>
                <td>4</td>
                <td>$156,000</td>
              </tr>
              <tr>
                <td>5</td>
                <td>$182,900</td>
              </tr>
              <tr>
                <td>6</td>
                <td>$209,800</td>
              </tr>
              <tr>
                <td>7</td>
                <td>$236,700</td>
              </tr>
              <tr>
                <td>8</td>
                <td>$263,600</td>
              </tr>
              {/* <tr>
                <td>9</td>
                <td>$243,800</td>
              </tr> */}
              {/* <tr>
                <td>10</td>
                <td>$265,400</td>
              </tr> */}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
}

function IconModalOpen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <defs>
        <path
          id="path-1"
          d="M0.00010952381 0.922909091L22.0734286 0.922909091 22.0734286 22.9088727 0.00010952381 22.9088727z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-192 -10)">
          <g transform="translate(192 10)">
            <g>
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <path
                fill="currentColor"
                d="M20.704.923H1.37C.612.923 0 1.533 0 2.286v11.957h2.738V3.65h16.597v16.532H8.701v2.727h12.003c.756 0 1.37-.61 1.37-1.364V2.286c0-.752-.614-1.363-1.37-1.363"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              fill="currentColor"
              d="M11.173 17.113c0 .724.633 1.312 1.413 1.312S14 17.837 14 17.113v-6.8C14 9.588 13.367 9 12.586 9H5.263c-.78 0-1.413.588-1.413 1.313s.633 1.312 1.413 1.312h3.91L.413 19.76a1.248 1.248 0 000 1.857c.277.256.639.384 1 .384.363 0 .724-.128 1-.384l8.76-8.135v3.632z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
