import React from "react";

export default function IconDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 17 19"
      {...props}
    >
      <defs>
        <path
          id="path-1"
          d="M0.000242857143 0L16.4495238 0 16.4495238 3 0.000242857143 3z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-196 -11)">
          <g transform="translate(196 11)">
            <g transform="translate(0 16)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <path
                fill="currentColor"
                d="M14.83 0H1.62C.724 0 0 .672 0 1.5S.725 3 1.62 3h13.21c.894 0 1.62-.672 1.62-1.5S15.724 0 14.83 0"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              fill="currentColor"
              d="M7.319 13.497c.304.321.732.503 1.18.503.45 0 .878-.182 1.181-.503l5.897-6.222c.6-.633.556-1.617-.095-2.199a1.64 1.64 0 00-2.265.093l-3.114 3.285V1.555C10.103.697 9.385 0 8.5 0S6.896.697 6.896 1.555v6.899L3.784 5.169a1.641 1.641 0 00-2.266-.093 1.524 1.524 0 00-.095 2.199l5.896 6.222z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
