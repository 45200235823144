import React from "react";

export default function IconArrowRight({ size = "24px", ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="currentColor"
      aria-labelledby="title"
      {...rest}
    >
      <title id="title" lang="en">
        Right arrow
      </title>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
    </svg>
  );
}
