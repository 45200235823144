import { DialogContent, DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { IconClose } from "@reusable";
import React from "react";
import "./styles.scss";

/**
 * Modal
 *
 * A wrapper of @reach/dialog with project
 * specific styles and props.
 */
export default function Modal({ handleDismiss, children, ...rest }) {
  function dismiss() {
    handleDismiss();
  }

  return (
    <DialogOverlay
      style={{
        background: "rgba(0, 0, 0, 0.7)",
        zIndex: "9001",
      }}
      onDismiss={dismiss}
    >
      <DialogContent
        aria-label="Important content"
        style={{
          position: "relative",
          borderRadius: "18px",
          boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)",
        }}
        {...rest}
      >
        {children}

        <button
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
          }}
          onClick={dismiss}
        >
          <IconClose
            style={{
              width: "32px",
              height: "32px",
              color: "#87189d",
            }}
          />
          <span className="visually-hidden">Dismiss</span>
        </button>
      </DialogContent>
    </DialogOverlay>
  );
}
