import { Button, COLORS, IconArrowRight, Link, PageHeader } from "@reusable";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import React from "react";
import { Helmet } from "react-helmet";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import FizzyCircle from "../fizzy-circle";
import faxYellow from "./fax-yellow.svg";
import person from "./person.svg";
import phoneYellow from "./phone-yellow.svg";
import "./styles.scss";

const features = [
  {
    heading: "Reimbursement<br/>Resources",
    details: [
      "Verification of Patient-Specific Insurance Benefits",
      "Billing and Coding Information",
      "Prior Authorization Assistance",
      "Appeals Resources",
    ],
    to: "/reimbursement/",
  },
  {
    heading: "Patient Support<br/>Services",
    details: [
      "Bridge Program       (Office Settings Only)",
      "Copay Assistance",
      "Patient Assistance Program (PAP)",
      "First-Cycle Patient Support (Hospital Outpatient Settings Only)",
      "Independent Charitable Foundation Information*",
    ],
    to: "/patient-support/",
  },
  {
    heading: "Resources at your Fingertips",
    details: [
      "User Guides",
      "Forms and Documents",
      "Reimbursement Tools",
      "Program Brochures",
    ],
    to: "/resources/",
  },
];

export default function HomePage() {
  const data = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "doctor-with-patient" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSupport: file(name: { eq: "woman-on-phone" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      imageSupportSmall: file(name: { eq: "woman-on-phone-small" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  /**
   * Use Gatsby Image Art Direction feature for
   * swapping to different images.
   *
   * https://www.gatsbyjs.com/plugins/gatsby-image/#art-directing-multiple-images
   */
  const imageSupport = [
    data.imageSupportSmall.childImageSharp.fluid,
    {
      ...data.imageSupport.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <>
      <Helmet>
        <title>ACCESS4Me | Patient Support</title>
        <link rel="canonical" href="https://www.access4me.com/" />
        <meta property="og:url" content="https://www.access4me.com/" />
        <meta name="twitter:url" content="https://www.access4me.com/" />
        <meta
          name="description"
          content="ACCESS4Me is a patient support program that offers help throughout the access process for ROLVEDON (eflapegrastim-xnst), benefits verification and assistance."
        />
        <meta
          name="keywords"
          content="access4me, eflapegrastim patient support, rolvedon patient support, eflapegrastim access, rolvedon access, eflapegrastim benefits, rolvedon benefits, rolvedon assistance, eflapegrastim patients, rolvedon patients
           "
        />
      </Helmet>

      <ParallaxProvider>
        <PageHeader>
          <div className="home-header short-hero">
            <Parallax y={["30px", "-20px"]}>
              <BackgroundImage
                aria-hidden="true"
                tag="div"
                fluid={data.image.childImageSharp.fluid}
                className="home-header-image"
              />
            </Parallax>
            <Parallax y={["40px", "-40px"]}>
              <div className="circle-1 grow" />
            </Parallax>
            <Parallax y={["50px", "-30px"]}>
              <div className="circle-2 grow" />
            </Parallax>

            <div className="home-header-content">
              <h1>Get started with ACCESS4ME®</h1>
              <p className="text-large">
                Patient Access and Reimbursement Support
              </p>
            </div>

            <Button to="/accessing-rolvedon/" kind="to-white-bg">
              Enroll Now <IconArrowRight />
            </Button>
          </div>
        </PageHeader>

        <div className="container home-header-image-small-wrapper">
          <Img
            aria-hidden="true"
            tag="div"
            fluid={data.image.childImageSharp.fluid}
            className="home-header-image-small"
            alt="Doctor with patient imagery"
          />
          <div className="circle-1 grow" />
          <div className="circle-2 grow" />
        </div>

        <div className="container">
          <div className="homepage-simplify homepage-simplify-small animate-on-enter-3">
            <img src={person} alt="Person icon" />
            <h2>
              SUPPORTING
              <br />
              PATIENT ACCESS
            </h2>
          </div>
        </div>

        <div className="container homepage-features-container animate-on-enter-2">
          <div className="circle-1 grow" />
          <div className="circle-2 grow" />

          <section className="homepage-features">
            {features.map((feature) => (
              <FizzyCircle key={feature.heading} to={feature.to}>
                <h2 dangerouslySetInnerHTML={{ __html: feature.heading }}></h2>
                <ul>
                  {feature.details.map((detail) => (
                    <li key={detail}>{detail}</li>
                  ))}
                </ul>

                <ArrowRight />
              </FizzyCircle>
            ))}
          </section>
        </div>

        <div className="container">
          <div className="homepage-simplify homepage-simplify-small animate-on-enter-3">
            <p>
              *Independent foundations have their own eligibility rules and we
              cannot guarantee a foundation will help you. We do not endorse or
              prefer any particular foundation.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="homepage-simplify homepage-simplify-large animate-on-enter-3">
            <img src={person} alt="Person icon" />
            <h2>
              SUPPORTING
              <br />
              PATIENT ACCESS
            </h2>
            <p>
              *Independent foundations have their own eligibility rules and we
              cannot guarantee a foundation will help you. We do not endorse or
              prefer any particular foundation.
            </p>
          </div>
        </div>

        <div className="home-support-wrapper">
          <section className="home-support">
            <div className="container">
              <div className="home-support-layout animate-on-enter-1">
                <Parallax y={["20px", "-40px"]}>
                  <Img
                    aria-hidden="true"
                    fluid={imageSupport}
                    className="home-support-image"
                    alt="Patient Support imagery"
                    objectFit="contain"
                  />
                </Parallax>
                <div>
                  <h2>We're here to support patients!</h2>

                  <p>
                    Our Field Reimbursement and Reimbursement Specialists are
                    available live or virtually—by phone, fax, and email.
                    Connect with an assigned Field Reimbursement Manager to
                    receive a complete overview of ACCESS4Me programs.
                  </p>

                  <p>
                    <i>
                      Communicate directly with an assigned Spectrum
                      Pharmaceuticals Field Reimbursement Manager or
                      Reimbursement Specialist.
                    </i>
                  </p>

                  <p>
                    <strong>ACCESS4Me:</strong>
                  </p>

                  <p className="center-start">
                    <img
                      src={phoneYellow}
                      alt="Phone icon"
                      style={{
                        marginRight: "1rem",
                      }}
                    />
                    <a href="tel:1-866-582-2737" className="text-large">
                      866-582-2737 (866-58-CARES)
                    </a>
                  </p>
                  <p className="center-start">
                    <img
                      src={faxYellow}
                      alt="Fax icon"
                      style={{
                        marginRight: "1rem",
                      }}
                    />
                    <span className="text-large">833-281-7416</span>
                  </p>

                  <p>
                    8:00 <span className="small-caps">AM</span> to 8:00{" "}
                    <span className="small-caps">PM</span> (ET), Monday - Friday
                  </p>
                </div>
              </div>

              <p className="home-support-visit center">
                <Link href="https://www.ACCESS4MePortal.com/">
                  Visit ACCESS4MePortal.com for online enrollment and access to
                  tools, forms and resources.
                </Link>
                <span
                  style={{
                    display: "inline-block",
                    color: "white",
                    background: COLORS.YELLOW,
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    marginLeft: "0.75rem",
                  }}
                >
                  <IconArrowRight />
                </span>
              </p>
            </div>
          </section>
        </div>
      </ParallaxProvider>
    </>
  );
}

/**
 * Inlined to use fill="currentColor"
 */
function ArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32"
      height="32"
      viewBox="0 0 34 48"
      fill="currentColor"
      className="arrow-right-icon"
      aria-labelledby="title"
    >
      <title id="title" lang="en">
        Right arrow
      </title>
      <defs>
        <path id="path-1" d="M0 0L34 0 34 48 0 48z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(0 -1)">
          <g transform="translate(0 1)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path
              fill="currentColor"
              d="M5.231 48c-1.445 0-2.884-.546-3.917-1.615-1.914-1.98-1.713-5.006.45-6.759L20.934 24.09 1.975 8.336c-2.138-1.777-2.3-4.805-.358-6.763 1.941-1.961 5.246-2.109 7.388-.33l23.28 19.343c1.1.916 1.723 2.216 1.715 3.578-.008 1.36-.65 2.653-1.763 3.555L8.695 46.797A5.497 5.497 0 015.231 48"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
