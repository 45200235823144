import { Button, COLORS, IconArrowRight, Logo, Modal } from "@reusable";
import { Link as GatsbyLink } from "gatsby";
import React, { useState } from "react";

/*
  The @reusable Link is responsible
  for catching external routes and
  opening a Modal to ask the user
  to confirm that they wish to leave
  this website.
*/
export default function Link({
  to,
  href = "/",
  affiliated = false,
  children,
  ...rest
}) {
  const [showModal, setShowModal] = useState(false);
  const url = to ? to : href;
  const isInternalRoute = url.startsWith("/");

  if (!isInternalRoute) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
  }

  function handleExternalLinkClick(e) {
    e.preventDefault();

    setShowModal(true);
  }

  function dismissModal() {
    setShowModal(false);
  }

  if (isInternalRoute) {
    return (
      <GatsbyLink to={url} {...rest}>
        {children}
      </GatsbyLink>
    );
  }

  const msg = affiliated
    ? "You are about to leave ACCESS4Me.com. Do you still want to continue?"
    : "You are about to leave ACCESS4Me.com. Do you still want to continue?";

  return (
    <React.Fragment>
      <a href={url} onClick={handleExternalLinkClick} {...rest}>
        {children}
      </a>
      {showModal && (
        <Modal handleDismiss={dismissModal}>
          <section
            style={{
              textAlign: "center",
            }}
          >
            <Logo />
            <p
              style={{
                fontSize: "1.25rem",
                color: COLORS.BLUE,
                fontWeight: "600",
                marginTop: "1rem",
              }}
            >
              {msg}
            </p>

            <div>
              <Button
                onClick={dismissModal}
                style={{
                  marginRight: "1.5rem",
                }}
              >
                No <IconArrowRight />
              </Button>
              <a {...rest} className="btn" as="a" href={url}>
                Yes <IconArrowRight />
              </a>
            </div>
          </section>
        </Modal>
      )}
    </React.Fragment>
  );
}
