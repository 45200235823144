import { Link } from "@reusable";
import React from "react";
import "./styles.scss";

/*
  FizzyCircle

  Circles animate out from the center
  and it fades from center out to new
  background color.

  Inspiration:
  - https://codepen.io/webLeister/pen/XwGENz
  - https://codepen.io/ishaansaxena/pen/WoJGRK
*/
export default function FizzyCircle({ to, children }) {
  return (
    <Link to={to} className="fizzy-circle">
      <div className="fizzy-inner-container">
        {children}
        <div className="fizzy-spot-container">
          {[...Array(52)].map((_, i) => (
            <div key={i} className="fizzy-spot" />
          ))}
        </div>
      </div>
    </Link>
  );
}
