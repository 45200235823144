import {
  Accordion,
  AccordionHeading,
  AccordionItem,
  AccordionPanel,
  Button,
  IconArrowRight,
  IconDownload,
} from "@reusable";
import React from "react";
import { Helmet } from "react-helmet";
import calendarGraphic from "./assets/calendar-graphic.svg";
import computerGraphic from "./assets/computer-graphic.svg";
import enrollmentGraphic from "./assets/enrollment-graphic.svg";
import faxGraphic from "./assets/fax-graphic.svg";
import "./styles.scss";

export default function AccessingRolontis() {
  return (
    <>
      <Helmet>
        <title>
          Accessing ROLVEDON® (eflapegrastim-xnst) | Patient Support
        </title>
        <link
          rel="canonical"
          href="https://www.access4me.com/accessing-rolvedon"
        />
        <meta
          property="og:url"
          content="https://www.access4me.com/accessing-rolvedon"
        />
        <meta
          name="twitter:url"
          content="https://www.access4me.com/accessing-rolvedon"
        />
        <meta
          name="description"
          content="Enroll your patients in ACCESS4Me online and access portal services and benefits summary for ROLVEDON."
        />
        <meta
          name="keywords"
          content="eflapegrastim enrollment, rolvedon enrollment, eflapegrastim portal, rolvedon portal, rolvedon online access, eflapegrastim online access, eflapegrastim benefits summary, rolvedon benefits summary"
        />
      </Helmet>
      <div className="container prose animate-on-enter-1 access-container">
        <StepGraphicSmall step={1} />

        <div className="access-step-layout">
          <StepOneGraphic />

          <div>
            <h2>Enroll your patients in ACCESS4Me</h2>
            <p className="text-larger">There are 2 ways to enroll:</p>

            <div className="access-step-one-layout">
              <div>
                <div className="enroll-image-container">
                  <img
                    src={computerGraphic}
                    alt="Computer icon"
                    className="purple-icons"
                  />
                </div>

                <div className="enroll-acc">
                  <p style={{ marginTop: 0 }}>
                    <strong className="block">ACCESS4ME ONLINE PORTAL</strong>
                    Enroll your patients through the ACCESS4Me provider portal.
                    A one-stop online destination for reimbursement and patient
                    support.
                  </p>

                  <Accordion collapsible>
                    <AccordionItem>
                      <AccordionHeading>Enrollment Process</AccordionHeading>
                      <AccordionPanel>
                        <div className="enrollment-process-container">
                          <img
                            src={enrollmentGraphic}
                            alt="Enrollment icon"
                            className="enrollment-process-image"
                          />

                          <ol className="fancy-ol">
                            <li>Log in to the ACCESS4Me Provider Portal</li>
                            <li>
                              Select Patient Enrollment and complete the
                              enrollment form.
                              <span
                                style={{
                                  display: "block",
                                  marginLeft: "1rem",
                                  fontStyle: "italic",
                                }}
                              >
                                Appropriately select the attestation and
                                signature options before submission
                              </span>
                            </li>
                            <li>Click “Continue” and “Submit” to send forms</li>
                          </ol>
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>

                  <p
                    className="text-small texttext"
                    style={{
                      marginTop: "-1.25rem",
                    }}
                  >
                    *Requires registration and e-signature setup.
                  </p>

                  <p
                    style={{
                      marginBottom: "3rem",
                    }}
                  >
                    <Button to="https://www.ACCESS4MePortal.com">
                      Access the Portal <IconArrowRight />
                    </Button>
                  </p>
                </div>
              </div>

              <div className="enroll-or-container">
                <div className="enroll-or-divider"></div>
                <span className="enroll-or-text">OR</span>
                <div className="enroll-or-divider"></div>
              </div>

              <div>
                <div className="enroll-image-container">
                  <img src={faxGraphic} alt="Fax icon" />
                </div>

                <div>
                  <p style={{ marginTop: 0 }}>
                    <strong className="block">FAX</strong>
                    Complete, sign, and fax the ACCESS4Me Program enrollment
                    form.
                    <br></br>
                    Fax:&nbsp;833-281-7416
                  </p>

                  <p
                    style={{
                      marginBottom: "4rem",
                    }}
                  >
                    <Button
                      as="a"
                      href="../../pdf/rolvedon-enrollment-form.pdf"
                      openInNewTab={true}
                      className="button"
                    >
                      <span
                        style={{
                          marginRight: "0.75rem",
                        }}
                      >
                        Download the form
                      </span>{" "}
                      <IconDownload />
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <StepGraphicSmall step={2} />

        <div className="access-step-layout">
          <StepTwoGraphic />
          <div>
            <h2>Receive your benefits summary</h2>
            <p className="text-larger">2 business days:</p>

            <div className="access-step-graphic-layout">
              <img src={calendarGraphic} alt="Calendar icon" />

              <div>
                <p style={{ marginTop: 0 }}>
                  Once a completed enrollment form is received, your practice
                  will receive a complete benefits summary within 2 business
                  days.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 * Inlining these SVGs allows the font Poppings to be used.
 */
function StepOneGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="163"
      height="149"
      viewBox="0 0 163 149"
      className="access-step-graphic"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-130 -456)">
          <g transform="translate(130 456)">
            <circle cx="130" cy="91" r="33" fill="#87189D"></circle>
            <text
              fill="#FFF"
              fontFamily="Poppins"
              fontSize="32"
              fontWeight="800"
            >
              <tspan x="124" y="103">
                1
              </tspan>
            </text>
            <circle cx="79.5" cy="131.5" r="17.5" fill="#FFB81C"></circle>
            <circle cx="45.5" cy="45.5" r="45.5" fill="#FFB81C"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}

function StepTwoGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="112"
      viewBox="0 0 74 112"
      className="access-step-graphic"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-219 -1082)">
          <g transform="translate(219 1082)">
            <circle cx="41" cy="33" r="33" fill="#87189D"></circle>
            <text
              fill="#FFF"
              fontFamily="Poppins"
              fontSize="32"
              fontWeight="800"
            >
              <tspan x="33" y="43">
                2
              </tspan>
            </text>
            <circle cx="17.5" cy="94.5" r="17.5" fill="#FFB81C"></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}

function StepGraphicSmall({ step = 0 }) {
  return (
    <div className="center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="214"
        height="66"
        viewBox="0 0 214 66"
        className="access-step-small-graphic"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-79 -580)">
            <g transform="translate(9 580)">
              <g transform="translate(70)">
                <g transform="translate(74)">
                  <circle cx="33" cy="33" r="33" fill="#87189D"></circle>
                  <text
                    fill="#FFF"
                    fontFamily="Poppins"
                    fontSize="32"
                    fontWeight="800"
                  >
                    <tspan x="25" y="44">
                      {step}
                    </tspan>
                  </text>
                </g>
                <ellipse
                  cx="165.5"
                  cy="32.5"
                  fill="#FFB81C"
                  rx="18.5"
                  ry="18"
                ></ellipse>
                <ellipse
                  cx="48.5"
                  cy="32.5"
                  fill="#FFB81C"
                  rx="18.5"
                  ry="18"
                ></ellipse>
                <ellipse
                  cx="11.5"
                  cy="32.5"
                  fill="#FFB81C"
                  rx="11.5"
                  ry="11"
                ></ellipse>
                <ellipse
                  cx="202.5"
                  cy="32.5"
                  fill="#FFB81C"
                  rx="11.5"
                  ry="11"
                ></ellipse>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
