import React from "react";

/**
 * <IconCollapse />
 *
 * SVG source: https://material.io/resources/icons/?search=minus&icon=remove&style=baseline
 */
export default function IconCollapse({ size = "24px", ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      fill="currentColor"
      {...rest}
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M19 13H5v-2h14v2z"></path>
    </svg>
  );
}
